export const environment = {
  env: 'latest',

  apis: {
    auth: 'https://auth.mbpi-latest.com',
    portal: 'https://portal.mbpi-latest.com',
  },

  agencyLogoBaseUrl: 'assets/images/agent',
  dealerLogoBaseUrl: 'assets/images/dealer',
  profilePhotoBaseUrl: 'assets/images/user',
  noLogoUrl: 'assets/images/nologo.png',
  noAvatar: 'noavatar.png',

  notificationAppID: 'c32b4bf5-b981-4855-8539-2fbff677d6a6',

  ngxs: {
    logger: 'false',
    devTools: 'false',
  },

  logRocket: {
    on: 'false',
    appId: 'none',
  },

  routing: {
    enableTracing: false,
  },

  version: {
    url: 'version.json',
    frequency: 1000 * 60 * 10,
  },

  legacyHostName: 'secure.mbpnetwork.com',
};
